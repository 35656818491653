
import {
  Service,
  ServiceStop,
  Stop
} from '@/models/Timetable'
import {
  defineComponent,
  PropType
} from 'vue'
import AlertIcon from '@/components/vectors/AlertIcon.vue'

export default defineComponent({
  components: {
    AlertIcon
  },
  props: {
    stops: {
      type: Object as PropType<Stop[]>,
      required: true
    },
    services: {
      type: Object as PropType<Service[]>,
      required: true
    },
    routeNumber: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const [mainServiceLine] = props.routeNumber.split('-')
    // eslint-disable-next-line complexity
    const stopLegend = (stop: ServiceStop): string => {
      if (stop === undefined) return ''
      if (stop.CustomNote) return stop.CustomNote

      if (stop.TypeId === 'BUS_STOP_TYPE.REQUEST_STOP_PICK_UP') {
        return 'P'
      } else if (stop.TypeId === 'BUS_STOP_TYPE.REQUEST_STOP_DROP_OFF') {
        return 'D'
      }

      return ''
    }
    // eslint-enable-next-line complexity
    const hasLayover = (layover, stop) =>
      layover && stop && stop?.ArrivalTime !== stop?.Time

    return {
      stopLegend,
      mainServiceLine,
      hasLayover
    }
  }
})
