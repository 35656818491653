
/* eslint-disable max-lines-per-function */
import { computed, defineComponent, onMounted, ref, watchEffect } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import RouteDescriptionSkeleton from '@/components/Skeletons/RouteDescriptionSkeleton.vue'
import GenericError from '@/components/GenericError.vue'
import { getRouteDescription } from '@/kentico-api/routes.api'
import useSwrv from 'swrv/dist/use-swrv'
import Tabs from '@/components/Tabs.vue'
import RouteList from '@/components/RouteList.vue'
import useTitle from '@/composables/useTitle'
import Spinner from '@/components/Spinner.vue'
import StopList from '@/components/Timetables/StopList.vue'
import { useHead } from '@vueuse/head'
import imageUrlBuilder from '@/kentico-api/imageUrlBuilder'
import Timetable from '@/components/Timetables/Timetable.vue'
import { getTimetable } from '@/expressway-api/timetable.api'
import dayjs from 'dayjs'
import {
  TimetableByDate
} from '@/models/Timetable'
import ExpandableContent from '@/components/ExpandableContent.vue'
import minMax from 'dayjs/plugin/minMax'
dayjs.extend(minMax)

export default defineComponent({
  name: 'Route',
  components: {
    PageHeader,
    GenericError,
    Tabs,
    RouteList,
    Spinner,
    RouteDescriptionSkeleton,
    StopList,
    Timetable,
    ExpandableContent
  },
  props: {
    routeId: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: false
    }
  },
  // eslint-disable-next-line complexity
  setup (props) {
    const { setTitle } = useTitle()
    const tabSelected = ref('timetable')
    const timetables = ref<TimetableByDate[]>([])
    const loadingTimetable = ref(true)
    const loadingTimetableError = ref(false)
    const today = ref(dayjs())
    const updateTabSelected = (newTab: string) => {
      tabSelected.value = newTab
    }
    const normalizeForKontent = (route: string) =>
      `route_${route.replace('-', '_').toLowerCase()}`

    const { data, error } = useSwrv(
      () => normalizeForKontent(props.routeId), getRouteDescription
    )

    const parseRoutes = routes =>
      routes.map(route => ({
        number: route.number,
        id: route.routeItineraryId,
        name: route.locations
      }))

    const loadTimetable = (date: dayjs.Dayjs) => {
      getTimetable({
        forDate: date.format('YYYY-MM-DD'),
        lineNumbers: props.routeId.toUpperCase().split('-')
      // eslint-disable-next-line complexity
      }).then(res => {
        timetables.value.push({
          ValidFrom: dayjs(res.ValidFrom).format('YYYY-MM-DD'),
          ValidTo: dayjs(res.ValidTo).format('YYYY-MM-DD'),
          Timetable: res
        })
        loadingTimetable.value = false

        if (res.NextTimetableOn !== null) {
          loadTimetable(dayjs(res.NextTimetableOn))
        }
      }).catch(() => {
        loadingTimetable.value = false
        loadingTimetableError.value = true
      })
    }

    const loadTimetables = () => {
      loadTimetable(today.value)
    }

    const loading = computed(() => !route.value && !error.value)

    const route = computed(() => {
      if (data?.value?.data?.data && Object.keys(data.value.data.data).length === 0) {
        return false
      }

      return data.value?.data?.data?.route
    })
    const errorFound = computed(() => {
      if (error.value) {
        return true
      }

      return false
    })

    const pdfFiles = computed(() =>
      route.value.description?.timetables?.items.map(
        item => item.timetableFile.items.map(item => item.url)).flat())

    const tabsOptions = computed(() => {
      if (route.value) {
        return [
          { value: 'timetable', label: 'Timetable' },
          { value: 'route_and_stops', label: 'Route & stops' }
        ]
      }

      return [{ value: 'timetable', label: 'Timetable' }]
    })

    // eslint-disable-next-line complexity
    watchEffect(() => {
      if (route.value && route.value.description?.metadataPageTitle) {
        setTitle(route?.value.description.metadataPageTitle)
      }
    })

    useHead({
      meta: [
        {
          name: 'og:description',
          content: computed(() => route.value && route.value.description?.openGraphOgDescription
            ? route.value.description.openGraphOgDescription
            : ''),
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: computed(() =>
            route.value &&
            route.value.description?.openGraphOgTitle
              ? route.value.description.openGraphOgTitle
              : ''),
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: `${process.env.VUE_APP_SITE_URL}routes-and-timetables`,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: computed(
            () => route.value && route.value.description?.openGraphOgImage?.length > 0
              ? imageUrlBuilder(route.value.description?.openGraphOgImage[0].url,
                { w: 800, auto: 'format' })
              : `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`),
          key: 'ogImage'
        },
        {
          name: 'og:type',
          content: 'website',
          key: 'ogType'
        },
        {
          name: 'og:locale',
          content: computed(() => route.value && route.value.description?.openGraphOgTitle
            ? route.value.description?.openGraphOgLocale
            : 'en_IE'),
          key: 'ogLocale'
        }
      ]
    })

    onMounted(() => {
      loadTimetables()
    })

    return {
      errorFound,
      data,
      route,
      tabsOptions,
      tabSelected,
      parseRoutes,
      updateTabSelected,
      loading,
      timetables,
      loadingTimetable,
      loadingTimetableError,
      pdfFiles,
      error
    }
  }
})
