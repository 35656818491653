
import {
  defineComponent,
  computed,
  ref
} from 'vue'
import useSwrv from 'swrv/dist/use-swrv'
import { getRoutesPage } from '@/kentico-api/routes.api'
import PageHeader from '@/components/PageHeader.vue'
import RouteSkeleton from '@/components/Skeletons/RouteSkeleton.vue'
import { useHead } from '@vueuse/head'
import imageUrlBuilder from '@/kentico-api/imageUrlBuilder'
import RouteList from '@/components/RouteList.vue'
import Spinner from '@/components/Spinner.vue'

export default defineComponent({
  name: 'Routes',
  components: {
    PageHeader,
    RouteSkeleton,
    RouteList,
    Spinner
  },
  // eslint-disable-next-line complexity
  setup () {
    const loadingRoutes = ref(true)
    const { data, error } = useSwrv(
      () => 'routes_page', getRoutesPage,
      {
        revalidateOnFocus: false,
        refreshInterval: 0
      }
    )
    const page = computed(() => data.value?.data)
    const downloadable = computed(() =>
      (data.value?.data.ctaDownloadableItem && data.value?.data.ctaDownloadableItem?.length > 0)
        ? data.value.data.ctaDownloadableItem[0].url
        : null)

    const routes = computed(() => page.value?.routesList.map(route => ({
      name: route.locations,
      number: route.number,
      id: route.routeItineraryId
    })))

    useHead({
      meta: [
        {
          name: 'og:description',
          content: computed(() => page.value?.openGraphOgDescription || ''),
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: computed(() => page.value?.openGraphOgTitle || ''),
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: `${process.env.VUE_APP_SITE_URL}routes-and-timetables`,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: computed(() => page.value && page.value.openGraphOgImage?.length > 0
            ? imageUrlBuilder(page.value.openGraphOgImage[0].url,
              { w: 800, auto: 'format' })
            : `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`),
          key: 'ogImage'
        },
        {
          name: 'og:type',
          content: 'website',
          key: 'ogType'
        },
        {
          name: 'og:locale',
          content: computed(() => page.value?.openGraphOgLocale || 'en_IE'),
          key: 'ogLocale'
        }
      ]
    })

    return {
      page,
      error,
      routes,
      loadingRoutes,
      downloadable
    }
  }
})
