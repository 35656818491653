import { httpClient } from './httpClient'
import { Timetable } from '@/models/Timetable'
import { Legend } from '@/models/Legend'
import { DayOption } from '@/models/DayOption'

const route = '/timetables'

export interface TimetableParams {
  forDate: string,
  lineNumbers: string[]
}

export interface PdfTimetableParams {
  forDate: string,
  routeName: string,
  routeNumber: string
}

const prepareParams = ({
  forDate,
  lineNumbers
}: TimetableParams) => ({
  ForDate: forDate,
  LineNumbers: lineNumbers
})

const getTimetable = async (params: TimetableParams): Promise<Timetable> =>
  httpClient
    .post(route, prepareParams(params))
    .then(response => response.data)

const getLegends = async (): Promise<Legend[]> =>
  httpClient
    .get(`${route}/legends`)
    .then(response => response.data.Legends)

const getDayOptions = async (): Promise<DayOption[]> =>
  httpClient
    .get(`${route}/day-options`)
    .then(response => response.data.DayOptions)

export {
  getTimetable,
  getLegends,
  getDayOptions
}
