
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true
    },
    lines: {
      type: Number,
      required: false,
      default: 1
    }
  },
  setup () {
    const showContent = ref(false)

    const toggleContent = () => {
      showContent.value = !showContent.value
    }

    return {
      toggleContent,
      showContent
    }
  }
})
