<template>
  <svg
    width="10"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.57702 13.2666L5 13.9376L5.42298 13.2666C5.6411 12.9206 5.85513 12.5834 6.06376 12.2547C6.93032 10.8895 7.70374 9.67097 8.29031 8.57598C9.01602 7.22123 9.5 5.97897 9.5 4.801C9.5 2.39304 7.34218 0.5 5 0.5C2.65836 0.5 0.5 2.39301 0.5 4.801C0.5 5.97897 0.983977 7.22123 1.70969 8.57598C2.29626 9.67097 3.06969 10.8895 3.93624 12.2547C4.14487 12.5834 4.3589 12.9206 4.57702 13.2666ZM5 6C4.44764 6 4 5.55236 4 5C4 4.44764 4.44764 4 5 4C5.55236 4 6 4.44764 6 5C6 5.55236 5.55236 6 5 6Z"
      fill="#C2002F"
      stroke="white"
    />
  </svg>
</template>
