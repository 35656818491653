<template>
  <svg
    width="86"
    height="55"
    viewBox="0 0 86 55"
    fill="#FBCAD4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M85.4643 2.85631C84.1467 4.25242 79.2543 9.27453 60.8937 27.8408L85.668 52.1952H85.7107V2.85631H85.4643ZM24.2576 0.712891C9.96471 0.712891 4.60243 0.725818 2.72628 0.741976L34.3644 32.594C36.671 34.9208 39.7366 36.2006 42.996 36.2006C46.2554 36.2006 49.3243 34.9208 51.6309 32.594L83.2559 0.751671C77.3941 0.745208 46.2916 0.712891 24.2576 0.712891ZM25.095 28.0526C6.73439 9.48307 1.84525 4.46096 0.527679 3.06808H0.28125V52.407H0.323964L25.095 28.0526ZM83.582 54.8824L58.834 30.5539L53.751 35.6729C50.8825 38.5653 47.0645 40.1553 43.0001 40.1553H42.9968C38.9357 40.1586 35.1177 38.5653 32.246 35.6729L27.1663 30.5539L2.41825 54.8824V54.9211V54.9244H83.582V54.9211V54.8824Z"
    />
  </svg>
</template>
