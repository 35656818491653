
import { defineComponent } from 'vue'
import ExpresswayLogo from '@/components/vectors/ExpresswayLogo.vue'
import Letter from '@/components/vectors/Letter.vue'
import Facebook from '@/components/vectors/Facebook.vue'
import Twitter from '@/components/vectors/Twitter.vue'
import Instagram from '@/components/vectors/Instagram.vue'

export default defineComponent({
  props: {
    routeNumber: {
      type: String,
      required: true
    }
  },
  components: {
    ExpresswayLogo,
    Letter,
    Facebook,
    Twitter,
    Instagram
  }
})
