
import {
  defineComponent,
  PropType,
  ref,
  computed
} from 'vue'
import ArrowDown from '@/components/vectors/ArrowDown.vue'

export default defineComponent({
  components: {
    ArrowDown
  },
  props: {
    stops: {
      type: Object as PropType<string[]>,
      required: true
    }
  },
  setup (props) {
    const expanded = ref(false)
    const filteredStops = computed(() => {
      if (expanded.value) return props.stops

      return [...props.stops.slice(0, 4), props.stops[props.stops.length - 1]]
    })
    return { expanded, filteredStops }
  }
})
