import httpClient from './httpClient'
import httpClientGraphql from './httpClientGraphql'
import { AxiosResponse } from 'axios'
import { Image } from '@/models/Image'
import { Asset } from '@/kentico-api'
import { Metadata } from '@/models/Metadata'
import { OpenGraph } from '@/models/OpenGraph'
import { ShortRoute } from '@/models/Route'
import { File } from '@/models/File'

export interface RoutesPage extends Metadata, OpenGraph {
  title: string;
  slug: string;
  image: Image;
  ctaText: string;
  ctaDownloadableItem: Asset[];
  routesList: ShortRoute[];
}

export interface RouteBaseInfo {
  locations: string;
  number: string;
  routeDescriptionSlug: string;
}

export interface BasicInfo {
  data: string;
}

export interface TimeTableFileItems {
  items: File;
}

export interface Timetable {
  key: string;
  timetableFile: TimeTableFileItems;
  validFrom: string;
}

export interface RouteDescription extends OpenGraph, Metadata {
  slug: string;
  title: string;
  introduction: string;
  routeStopsServices: string;
  buyingTicketInfo: BasicInfo;
  moreRoutes: ShortRoute[];
  baseInfo: ShortRoute;
  timetables: Timetable[];
}

export interface Route {
  name: string;
  key: string;
  number: string;
  locations: string;
  routeDescriptionSlug: string;
  description: RouteDescription;
  routeItineraryId: string;
}

const getRoutesPage = (): Promise<AxiosResponse<RoutesPage>> =>
  httpClient.get<RoutesPage>('', {
    params: {
      'system.type': 'routes_page',
      'elements.slug': 'index'
    }
  })

const getRouteDescription = (routeId: string): Promise<AxiosResponse> =>
  httpClientGraphql.post<Route>('', {
    query: `
      query Route {
        route(codename: "${routeId}") {
          locations
          reverseLocations
          number
          routeDescriptionSlug
          routeItineraryId
          description {
            slug
            title
            introduction {
              html
            }
            timetables {
              items {
                validFrom
                timetableFile {
                  items {
                    url
                    description
                    name
                    size
                    type
                  }
                }
              }
            }
            routeStopsServices {
              html
            }
            buyingTicketInfo {
              data {
                html
              }
            }
            moreRoutes {
              items {
                locations
                number
                routeDescriptionSlug
                routeItineraryId
              }
            }
          }
        }
      }
    `
  })

export { getRoutesPage, getRouteDescription }
