
import { defineComponent } from 'vue'
import RouteCard from '@/components/RouteCard.vue'

export default defineComponent({
  components: {
    RouteCard
  },
  props: {
    title: {
      type: String,
      required: false
    },
    routes: {
      type: Object,
      required: true
    }
  }
})
