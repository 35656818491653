
import {
  defineComponent,
  PropType
} from 'vue'
import { Legend } from '@/models/Legend'

export default defineComponent({
  components: {
  },
  props: {
    legends: {
      type: Object as PropType<Legend[]>
    },
    tripInformation: {
      type: Object as PropType<string[]>
    }
  }
})
